import thankyouIntentOOI from '@wix/pro-gallery-artstore-statics/dist/src/applications/thankyou/thankyouIntentOOI';

import { withStyles } from '@wix/native-components-infra';

export default {
  component: withStyles(thankyouIntentOOI, {
    cssPath: ['thankyou.min.css'],
    strictMode: false,
  }),
};
