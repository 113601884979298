"use strict";

exports.__esModule = true;
exports["default"] = void 0;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function createWixSdk(data) {
  return {
    updateData: function updateData(newData) {
      data = _objectSpread(_objectSpread({}, data), newData);
    },
    addEventListener: function addEventListener() {},
    removeEventListener: function removeEventListener() {},
    Events: {
      EDIT_MODE_CHANGE: 'EDIT_MODE_CHANGE'
    },
    Performance: {
      applicationLoaded: function applicationLoaded() {}
    },
    isMock: true,
    getFontsHrefs: function getFontsHrefs() {
      var cssUrls = ['https://static.parastorage.com/services/third-party/fonts/Helvetica/fontFace.css'];
      var removeHttp = function removeHttp(href) {
        return href.replace('http:', '');
      };
      var removeHttps = function removeHttps(href) {
        return href.replace('https:', '');
      };
      var addUrl = function addUrl(href) {
        var _href = removeHttp(href);
        _href = removeHttps(_href);
        cssUrls.push('https:' + _href);
      };
      if (data.style) {
        var _data$style = data.style,
          fonts = _data$style.fonts,
          styleParams = _data$style.styleParams;
        if (fonts.cssUrls) {
          fonts.cssUrls.forEach(function (url) {
            return addUrl(url);
          });
        }
        if (styleParams.googleFontsCssUrl) {
          addUrl(styleParams.googleFontsCssUrl);
          cssUrls.concat(styleParams.googleFontsCssUrl);
        }
      }
      return cssUrls;
    },
    getSiteInfo: function getSiteInfo(callback) {
      var info = {
        pageTitleOnly: 'art-store',
        siteDescription: '',
        siteKeywords: '',
        url: data.baseUrl || '',
        baseUrl: data.baseUrl || '',
        referer: '',
        pageTitle: 'art-store'
      };
      callback(info);
    },
    Utils: {
      getSectionUrl: function getSectionUrl(_data, callback) {
        var sectionId = _data.sectionId;
        var url = '';
        if (data && data.sectionsUrls) {
          url = data.sectionsUrls[sectionId].url;
          callback({
            url: url
          });
        } else {
          if (data && data.baseUrl) {
            var segment = '';
            switch (sectionId) {
              case 'checkout_page':
                segment = 'checkout-page';
                break;
              case 'thank_you_page':
                segment = 'thank-you-page';
                break;
              default:
                break;
            }
            url = {
              url: data.baseUrl + '/' + segment
            };
          }
          if (callback) {
            callback(url);
          }
        }
      },
      getInstanceValue: function getInstanceValue(type) {
        switch (type) {
          case 'biToken':
            return data.metaSiteId;
          case 'aid':
            return data.visitorId;
          default:
            return data.visitorId;
        }
      },
      getLocale: function getLocale() {
        return data.locale || '';
      },
      getDeviceType: function getDeviceType() {
        return data.deviceType || '';
      },
      getViewMode: function getViewMode() {
        return data.viewMode || '';
      }
    },
    Styles: {
      getColorByreference: function getColorByreference(strColor) {
        if (data.style) {
          var color = data.style.siteColors.find(function (color) {
            return color.reference === strColor;
          });
          return color;
        } else {
          return '';
        }
      },
      getStyleFontByReference: function getStyleFontByReference(strFont) {
        if (data.style) {
          var font = data.style.siteTextPresets[strFont];
          return font;
        } else {
          return '';
        }
      },
      getEditorFonts: function getEditorFonts() {
        return [];
      }
    }
  };
}
var _default = exports["default"] = createWixSdk;